import {Moment} from 'moment'

export enum FocusedInput {
  StartDate = 'startDate',
  EndDate = 'endDate'
}

export interface Dates<T = Moment> {
  startDate: T | null
  endDate: T | null
}

export interface QuickSelectionItem {
  key?: string
  type: string
  title: string
  dates: Moment[]
}

export interface DateRange<T = Moment> {
  from: T
  to: T
  quickSelectionId?: string | null
}

export const isoWeekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
] as const
export type IsoWeekDay = (typeof isoWeekDays)[number]

// alias to clarify when we use Iso8601 strings (fully qualified or in UTC)
export type Iso8601 = string

export type TimeZone = string

export type DateTime = string | Date
